import React from "react"
import { Container, Table, Header, Icon, Breadcrumb } from "semantic-ui-react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ReferencesPage = ({ data }) => (
  <Layout>
    <SEO title="Textbooks" />
    <Container text style={{ marginTop: "100px", marginBottom: "100px" }}>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          Home
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>References</Breadcrumb.Section>
      </Breadcrumb>

      <Header as="h1" dividing>
        <Icon name="bookmark" />
        <Header.Content>
          References
          <Header.Subheader>
            List of references
          </Header.Subheader>
        </Header.Content>
      </Header>
      <p>
        Here you can find the list of references that inspired progmiscon.
      </p>
      <Table celled compact collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Year</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Authors</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.allZoteroItems.edges.map((edge) => {
            const { citationKey, data } = edge.node;
            const { parsedDate, title, creatorSummary } = data;
            return (
              <Table.Row key={citationKey}>
                <Table.Cell>
                  {parsedDate ? parsedDate.substring(0,4) : ""}
                </Table.Cell>
                <Table.Cell>
                  <Link
                    to={`/reference/${citationKey}`}
                  >
                    {title}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {creatorSummary}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </Container>
  </Layout>
)

export default ReferencesPage

export const query = graphql`
  query {
    allZoteroItems(sort: {order: DESC, fields: data___parsedDate}) {
      edges {
        node {
          id
          citationKey
          data {
            key
            title
            parsedDate,
            creatorSummary,
          }
        }
      }
    }    
  }
`
